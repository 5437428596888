import appAxios from 'src/app-axios';
import Constants from 'src/constants';

export const members = {
    namespaced: true,
    state: {
        membersList: [],
        inviteMemberList: [],
        memberRoles: [],
    },
    getters: {
        getMembersList (state) {
            return state.membersList;
        },
        getInviteMembersList (state) {
            return state.inviteMemberList;
        },
        getMembersRolesList (state) {
            return state.memberRoles;
        },
    },
    mutations: {
        setMembersList (state, data) {
            state.membersList = data;
        },
        setInviteMembersList (state, data) {
            state.inviteMemberList = data;
        },
        setMemberRoles (state, data) {
            state.memberRoles = data;
        },
    },
    actions: {
        async fetchMembersList ({ dispatch, commit }) {
            const url = 'api/organizations/users';
            try {
                const details = await appAxios.get(url);
                commit('setMembersList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the active members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateMembersList ({ dispatch }, payload) {
            const url = `api/members/${payload.userId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this member.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteMember ({ dispatch }, payload) {
            const url = `api/members/${payload.userId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this member.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchInviteMembersList ({ dispatch, commit }, payload) {
            const url = 'api/members/invitations';
            try {
                const details = await appAxios.get(url, payload.params);
                commit('setInviteMembersList', details.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the invite members list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateInviteMembersList ({ dispatch }, payload) {
            const url = `api/members/invitations/${payload.userId}`;
            try {
                await appAxios.put(url, payload.updateDetails);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in updating this Invite member.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async createRole ({ dispatch }, payload) {
            const url = 'api/members/roles';
            try {
                await appAxios.post(url, payload.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this role.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async InviteMember ({ dispatch }, payload) {
            const url = 'api/members/invitations';
            try {
                await appAxios.post(url, payload.details);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in this invitation.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchMemberRoles ({ dispatch, commit }) {
            const url = 'api/members/roles';
            try {
                const memberRoles = await appAxios.get(url);
                commit('setMemberRoles', memberRoles.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting member roles list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async updateMemberRole ({ dispatch }, payload) {
            const url = `api/members/roles/${payload.roleId}`;
            try {
                await appAxios.put(url, payload.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in setting the app users work hours.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async deleteMemberRole ({ dispatch }, payload) {
            const url = `api/members/roles/${payload.roleId}`;
            try {
                await appAxios.delete(url);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in deleting this member role.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
