import axios from 'src/app-axios';
import Constants from 'src/constants';
import moment from 'moment';

export const widgets = {
    namespaced: true,
    state: {
        alertHistories: [],
        alertResponses: [],
        responseStatus: {},
        responseStatusUpdatedAt: '',
        suspiciousCheckIns: [],
        manualCheckOuts: [],
    },
    getters: {
        getAlertHistories (state) {
            return state.alertHistories;
        },
        getAlertResponses (state) {
            return state.alertResponses;
        },
        getResponseStatus (state) {
            return state.responseStatus;
        },
        getResponseLastUpdatedAt (state) {
            return state.responseStatusUpdatedAt;
        },
        getSuspiciousCheckIns (state) {
            return state.suspiciousCheckIns;
        },
        getManualCheckOuts (state) {
            return state.manualCheckOuts;
        },
    },
    mutations: {
        setAlertHistories (state, histories) {
            state.alertHistories = histories;
        },
        setAlertResponses (state, responses) {
            state.alertResponses = responses;
        },
        setResponseStatus (state, { appUserExtId, event }) {
            if (appUserExtId in state.responseStatus) {
                state.responseStatus[appUserExtId].unshift(event.response);
                return;
            }
            state.responseStatus[appUserExtId] = [event.response];
        },
        setResponseUpdatedAt (state, dateTime) {
            state.responseStatusUpdatedAt = dateTime;
        },
        setSuspiciousCheckIns (state, data) {
            state.suspiciousCheckIns = data;
        },
        setManualCheckOuts (state, checkouts) {
            state.manualCheckOuts = checkouts;
        },
    },
    actions: {
        async fetchAlertHistories ({ dispatch, commit }) {
            const url = 'api/alert-messages';
            try {
                const histories = await axios.get(url);
                commit('setAlertHistories', histories.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the alert histories list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        async sendEmergencyMessage ({ dispatch }, payload) {
            const url = 'api/alert';
            try {
                await axios.post(url, payload.emergencyMessage);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in sending the message.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchAlertResponses ({ dispatch, commit }, payload) {
            const url = `api/alert-messages/${payload.alertId}/responses`;
            try {
                const responses = await axios.get(url);
                commit('setAlertResponses', responses.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the alert responses list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
            }
        },
        addResponseStatus (context, event) {
            context.commit('setResponseStatus', {
                appUserExtId: event.app_user_id,
                event: event.app_user_response,
            });
            context.commit('setResponseUpdatedAt', moment().toISOString());
        },
        async fetchSuspiciousCheckIns ({ dispatch, commit }) {
            const url = 'api/suspicious-activity';
            try {
                const responses = await axios.get(url);
                commit('setSuspiciousCheckIns', responses.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the suspicious check-ins list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchFilteredSuspiciousRecords ({ dispatch, commit }, payload) {
            const url = 'api/suspicious-activity';
            try {
                const records = await axios.get(url, payload);
                commit('setSuspiciousCheckIns', records.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the suspicious records list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchManualCheckOuts ({ dispatch, commit }) {
            const url = 'api/manual-checkout-records';
            try {
                const checkouts = await axios.get(url);
                commit('setManualCheckOuts', checkouts.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the manual check-outs list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async fetchFilteredManualCheckOuts ({ dispatch, commit }, payload) {
            const url = 'api/manual-checkout-records';
            try {
                const records = await axios.get(url, payload);
                commit('setManualCheckOuts', records.data);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in getting the filtered manual check-outs list.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
    },
};
