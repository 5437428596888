import Vue from 'vue';
import Router from 'vue-router';
import { dsAppDomainUrl } from 'src/app-globals';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Auth
    {
      path: '/',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
    },
    // Auth
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'pages/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '/user',
          component: () => import('@/views/dashboard/ProfileSettings/ProfileSettings'),
        },
        // support
        {
          name: 'Support Tickets',
          path: '/support',
          component: () => import('@/views/dashboard/support/SupportTickets'),
        },
        {
          name: 'Support Ticket Chats',
          path: '/support/tickets/:ticketId/chats',
          component: () => import('@/views/dashboard/support/SupportTicketChats'),
        },
        // Account Settings
        {
          name: 'Settings',
          path: '/settings',
          component: () => import('@/views/settings/Settings'),
        },
        // Settings
        {
          name: 'Feature Settings',
          path: '/feature-settings',
          component: () => import('@/views/dashboard/settings/SettingsLandingPage'),
        },
        // App Users
        {
          name: 'App Users',
          path: '/app-users',
          component: () => import('@/views/dashboard/appusers/AppUsersList'),
        },
        {
          name: 'App User Details',
          path: '/app-users/:externalId',
          component: () => import('@/views/dashboard/appusers/AppUserLandingPage'),
        },
        {
          name: 'App Users Structure',
          path: '/app-users/group',
          component: () => import('@/views/dashboard/appusers/appusersgrouping/CreateAppUsersStructure'),
        },
        // Projects
        {
          name: 'Projects',
          path: '/projects',
          component: () => import('@/views/dashboard/projects/ProjectsList'),
        },
        {
          name: 'Project Details',
          path: '/projects/:projectId/details',
          component: () => import('@/views/dashboard/projects/ProjectSecondNavDrawer'),
        },
        {
          name: 'Budget Details',
          path: '/projects/:projectId/budgets/:budgetId/details',
          component: () => import('@/views/dashboard/projects/budgets/BudgetsSecondNavDrawer'),
        },
        {
          name: 'Project Company App Users',
          path: '/projects/:projectId/project-companies/:companyId/app-users',
          component: () => import('@/views/dashboard/projects/companies/ProjectCompanyAppUsers'),
        },
        {
          name: 'Project Contract Budget Items',
          path: '/projects/:projectId/contracts/:contractId/details',
          component: () => import('@/views/dashboard/projects/contracts/ContractSecondNavDrawer'),
        },
        {
          name: 'Performance Tracking Item Instances',
          path: '/projects/:projectId/performance-tracking-items/:trackingItemId/instances',
          component: () => import('@/views/dashboard/projects/performancetracking/TrackingItemInstances'),
        },
        {
          name: 'Performance Tracking Item Instance Details',
          path: '/projects/:projectId/performance-tracking-items/:trackingItemId/instances/:trackingItemInstanceId/details',
          component: () => import('@/views/dashboard/projects/performancetracking/timesheets/InstanceSecondNavDrawer'),
        },
        // Widgets
        {
          name: 'Widgets',
          path: '/widgets',
          component: () => import('@/views/dashboard/widgets/Widgets'),
        },
        {
          name: 'Widgets',
          path: '/widgets/alerts/:alertId/responses',
          component: () => import('@/views/dashboard/widgets/emergencymessages/EmergencyMessageResponses'),
        },
        // Project Gates
        {
          name: 'Project Gates',
          path: '/project-gates',
          component: () => import('@/views/dashboard/projectgates/ProjectGatesList'),
        },
        {
          name: 'Group Project Gates',
          path: '/project-gates/group',
          component: () => import('@/views/dashboard/projectgates/projectgatesgrouping/CreateProjectGatesStructure'),
        },
        {
          name: 'Add Project Gate',
          path: '/project-gates/create',
          component: () => import('@/views/dashboard/projectgates/AddProjectGate'),
        },
        {
          name: 'Edit Project Gate',
          path: '/project-gates/:projectGateId/edit',
          component: () => import('@/views/dashboard/projectgates/EditProjectGateDetails'),
        },
        {
          name: 'Project Gate Check-Ins',
          path: '/project-gates/:projectGateId/check-ins',
          component: () => import('@/views/dashboard/projectgates/ProjectGateCheckIns'),
        },
        {
          name: 'Google Maps',
          path: 'project-gates/create/google-maps',
          component: () => import('@/views/dashboard/projectgates/ProjectGateGeolocation'),
        },
        {
          name: 'Edit Google Maps',
          path: '/project-gates/:projectGateId/edit/google-maps',
          component: () => import('@/views/dashboard/projectgates/ProjectGateGeolocation'),
        },
        // Records
        {
          name: 'Check-In Records',
          path: 'records',
          component: () => import('@/views/dashboard/records/CheckInRecords'),
        },
        {
          name: 'Record Details',
          path: 'records/:recordId',
          component: () => import('@/views/dashboard/records/CheckInRecordDetails'),
        },
         // Work Orders
        {
          name: 'workOrders',
          path: '/work-orders',
          component: () => import('@/views/dashboard/workorders/WorkOrders'),
        },
        {
          name: 'workOrder CheckIns',
          path: '/work-orders/:workOrderId',
          component: () => import('@/views/dashboard/workorders/WorkOrderCheckIns'),
        },
        // Companies
        {
          name: 'Companies List',
          path: '/companies',
          component: () => import('@/views/dashboard/companies/CompaniesList'),
        },
        // partners
        {
          name: 'Partner Auth Redirect',
          path: '/partners/auth-redirect',
          component: () => import('@/views/dashboard/partnerauth/PartnerAuthRedirectPage'),
        },
        // Members
        {
          name: 'Members List',
          path: '/members',
          component: () => import('@/views/dashboard/members/Members'),
        },
        // Attendance
        {
          name: 'Attendance',
          path: '/attendance',
          component: () => import('@/views/dashboard/attendance/Attendance'),
        },
        // Logout
        {
          path: '/logout',
          name: 'Logout',
          component: () => import('@/views/auth/Logout'),
        },
        // unknown routes
        {
          name: 'unknown',
          path: '*',
          component: () => import('@/views/dashboard/Unknown'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('scp-user');
  to.meta.previousRoute = from;
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    window.location.href = dsAppDomainUrl + '/logout-all';
    return;
  }

  next();
});

export default router;
